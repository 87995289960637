<template>
  <div id="home" class="w-full h-screen pt-32 lg:pt-0 flex flex-col items-center justify-center bg-white">
    <div class="absolute" style="top: 15%; left: 5%">
      <span class="ibmPlex text-md">SARUGAMI&nbsp;&nbsp;&nbsp;猿神 </span>
    </div>
    {{message}}
    <br />
    {{allGood === true ? 'All good, you can return to Discord now' : ''}}
    <br />
    <br />
    <img src="../assets/images/loader.gif" class="h-40" v-if="loading">
    <br />
    <button @click="signMessages" v-if="message !== 'Get ready to battle!' && !allGood"
      class="bg-primary text-white font-semibold py-2 px-4 border border-primary hover:border-secondary rounded-xl">
      Let's go
    </button>
  </div>
</template>

<script>
import { ethers } from "ethers";
import axios from "axios";

export default {
  name: "Connect",
  data() {
    return {
      message: "Connect your wallet to the website",
      loading: false,
      allGood: false
    }
  },
  methods: {
    async signMessage() {
      try {
        if (!window.ethereum)
          throw new Error('No crypto wallet found. Please install it.')

        await window.ethereum.send('eth_requestAccounts')
        const search = window.location.search
        const query = new URLSearchParams(search)
        const iduser = query.get('iduser')
        this.loading = true;
        this.message = "Sign the message on Metamask"

        let message = 'User Discord: ' + iduser + '\n nonce: ' + query.get('nonce')
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const signature = await signer.signMessage(message)
        const address = await signer.getAddress()

        return {
          signature,
          address,
          iduser
        }
      } catch (err) {
        console.log(err.message)
        console.log('2')
      }
    },
    async signMessages () {
      await this.signMessage()
        .then((res) => {
          axios
            .post(
              'https://xm1mb01sa5.execute-api.us-east-1.amazonaws.com/ROAAR/sign',
              res
            )
            .then((response) => {
              if (response.data === true) {
                this.message = 'Get ready to battle!'
                this.allGood = true;
                this.loading = false;
              } else {
                this.message = 'Something goes wrong, open a ticket on Discord in channel Support'
                this.loading = false;
              }
            })
            .catch((err) => {
              this.message = 'Something goes wrong, open a ticket on Discord in channel Support'
              this.loading = false;
              console.error(err)
            })

        })
        .catch((error) => {
            console.log(error)
            this.message = 'Something goes wrong, open a ticket on Discord in channel Support'
          }
        )
        .finally()
    }
  }
}
</script>

<style scoped>

</style>
