<template>
  <div class="flex flex-col items-center justify-center bg-secondary fixed w-full z-20">
    <div class="flex flex-row items-center justify-between w-full h-full lg:px-20 py-5 2k:py-8 4k:py-10 hidden lg:flex">
      <div class="flex flex-row items-center justify-center gap-10 2xl:gap-20 headerClass h-full"
      >
        <a href="/">
          <img src="../../assets/images/logo.svg" class="w-10 2k:w-18 4k:w-20">
        </a>
        <div v-for="(page, index) in pages"
             v-bind:key="index"
             class="h-full flex flex-col items-center cursor-pointer text-quaternary text-sm 2xl:text-base inter 2k:text-xl 4k:text-4xl"
             @click="goToPageOrSection(page.link, true, page.soon)">
          <div :id="page.link + 'Link'"></div>
          <div :id="page.link + 'Name'" :class="page.soon ? 'cursor-not-allowed hoverHide' : ''">
            <span class="hidden text-primary absolute mt-5 font-bold text-sm">[Soon]</span>
            {{ page.name }}
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center h-full">
        <Socials/>
      </div>
    </div>
    <div class="flex flex-row items-center justify-between w-full fixed p-5 lg:hidden bg-secondary"
         style="top: 0px;">
      <div>
        <a href="/">
          <img src="../../assets/images/logo.svg"
               style="height: 40px">
        </a>
      </div>
      <div>
        <BurgerMenu/>
      </div>
    </div>
  </div>
</template>

<script>

import BurgerMenu from "@/components/header/BurgerMenu";
import Socials from "@/components/Socials";

export default {
  name: 'Header',
  components: { Socials, BurgerMenu },
  data: () => {
    return {
      pages: [
        { link: '', name: 'Home', soon: false },
        { link: '#vision', name: 'Vision', soon: false },
        { link: '#roadmap', name: 'Roadmap', soon: false },
        { link: '#team', name: 'Team', soon: false },
        { link: 'lore', name: 'Lore', soon: false },
        { link: 'rarity', name: 'Rarity', soon: false },
        { link: 'sarugami-one-shots', name: 'Mint', soon: false },
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      const page = this.$route.hash;
      this.goToPageOrSection(page.toLowerCase(), false, false)
    }, 1000)
  },
  watch: {
    '$route'(to) {
      setTimeout(() => {
        try {
          document.getElementById(to.hash.split("#")[1]).scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        } catch (e) {
          //
        }
      }, 100)
    }
  },
  methods: {
    goToPageOrSection(id, redirect, soon) {
      if (redirect && soon === false) {
        this.$router.push("/" + id)
      }

      if (soon === false) {
        this.removeClassName()
        let node = document.getElementById(id + "Link")
        let node2 = document.getElementById(id + "Name")
        node.classList.add('lineYellow');
        node2.classList.add('font-bold');
      }
    },
    removeClassName() {
      this.pages.forEach((page) => {
        let node = document.getElementById(page.link + "Link")
        let node2 = document.getElementById(page.link + "Name")
        node.classList.remove('lineYellow');
        node2.classList.remove('font-bold');
      })
    }
  }
}
</script>

<style scoped
       lang="scss">
@import "./src/assets/css/variables";

.hoverHide:hover span {
  display: block;
  position: fixed;
  background-color: $secondary;
}

.lineYellow {
  border-top: 13px solid $primary;
  border-radius: 10px;
  margin-top: -29px;
  position: absolute;
  width: 80px;
}
</style>
