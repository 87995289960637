<template>
  <div class="flex flex-col items-center justify-center w-full h-full bg-secondary p-10 gap-10">
      <img src="../assets/images/logo.svg">
      <Socials/>
  </div>
</template>

<script>

import Socials from "@/components/Socials";
export default {
  name: 'Footer',
  components: { Socials },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>