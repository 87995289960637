<template>
	<div id="app">
		<Header />
		<div class="self-center flex-grow w-full">
			<router-view :key="$route.path" />
		</div>
		<ModalMint v-show="isModalMintOpen" />
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/header/Header';
import Footer from '@/components/Footer';
import store from '@/store';
import ModalMint from '@/components/modals/ModalMint';
import { mapActions } from 'vuex';

export default {
	name: 'App',
	components: { ModalMint, Footer, Header },
	async mounted() {
		//window.ethereum.on('accountsChanged', (accounts) => {
		//this.changeWallet(accounts[0])
		//});
		//let result = await connectMetamask()
		//if (result.success) {
		//this.changeWallet(result.msg)
		//}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				document.title = 'Sarugami';
			},
		},
	},
	computed: {
		isModalMintOpen() {
			return store.state.isModalMintOpen;
		},
	},
	methods: {
		...mapActions(['changeWallet']),
	},
};
</script>

<style></style>
