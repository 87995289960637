<template>
  <div class="w-full h-10">
    <button
      class="w-full h-full bg-primary text-white font-semibold py-2 px-4 border border-primary hover:border-secondary rounded-xl">
      Free Mint
    </button>
  </div>

</template>

<script>


export default {
  name: 'BuyButton',
}
</script>

<style scoped>

</style>