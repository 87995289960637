<template>
  <div class="flex lg:flex-row flex-col items-center justify-between w-full h-full py-20 bg-tertiary bgWavesGray"
       id="vision">
    <div class="flex flex-col justify-center items-center w-full ">
      <div>
        <div class="flex flex-col justify-center items-center">
          <div class="lg:w-3/6 pt-5 text-center lg:text-left m-1 text-sm lg:text-lg">
            <p class="ibmPlex pb-5 text-5xl text-primary font-bold 2k:text-8xl">
              VISION
            </p>
            <p
              class="text-quaternary text-sm 2xl:text-base 2k:text-xl 4k:text-3xl poppins leading-9 text-center lg:text-left ">
              The love of art, community, and branding is at the heart of Sarugami, and this passion runs through our
              project. Bridging Web2 to Web3, our goal is to continue to tell and enrich our story over
              multiple forms.
              <br/>
              <br/>
              We believe it is critical to provider holders with utility — a reason to hold these NFTs beyond
              their beauty. That is why we are constantly working to provide more value to you and the greater community. 
              <br/>
              <br/>
              We are here to stay, together with you. OSS!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-10 lg:block hidden flex flex-col">
      <img src="../../assets/images/backgrounds/vision.svg">
      <div class="ibm w-full h-full flex flex-row items-end justify-end px-14 pt-10 text-white">
        <span style="color: #E5E5E5" class="ibmPlex text-md">NFT&nbsp;&nbsp;&nbsp;猿神 </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisionSection"
}
</script>

<style scoped
       lang="scss">
@import "./src/assets/css/variables";


</style>