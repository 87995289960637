import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/Home'
import Lore from "@/pages/Lore";
import ConnectGame from "@/pages/ConnectGame";
import Rarity from "@/pages/Rarity";
import Staking from "@/pages/Staking";
import MintOneShots from "@/pages/MintOneShots";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      publicRoute: true,
      title: `Sarugami NFT`,
      metaTags: [
        {
          name: 'keywords',
          content: 'Sarugami, NFT, SarugamiNFT , Apes, Samurapes, Samur Apes, Saru, Gami, nfts, anime, cartoon, warriors'
        },
        {
          name: 'description',
          content: 'Home page describing he project'
        },
        {
          property: 'twitter:description',
          content: '@SarugamiNFT Sarugami is culture. 2510 ETH Warriors will grant you access to our marketplace.'
        },
        {
          property: 'twitter:image',
          content: 'https://pbs.twimg.com/profile_images/1525988646326788096/W4ye8Lge_400x400.jpg'
        },
        {
          property: 'twitter:card',
          content: 'https://sarugami.io'
        }
      ]
    }
  },
  {
    path: '/lore',
    name: 'Lore',
    component: Lore
  },
  {
    path: '/staking',
    name: 'Staking',
    component: Staking
  },
  {
    path: '/connect',
    name: 'Connect Game',
    component: ConnectGame
  },
  {
    path: '/rarity',
    name: 'Rarity',
    component: Rarity
  },
  {
    path: '/sarugami-one-shots',
    name: 'Sarugami: One Shots',
    component: MintOneShots
  },
  {
    path: "/:catchAll(.*)",
    component: Home,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (!to.hash) {
      document.getElementById('app').scrollIntoView();
    }
  },
})

export default router
