<template>
  <button
      class="w-40 bg-primary text-white font-semibold hover:text-gray-500 py-2 px-4 border border-primary hover:border-secondary hover:border-transparent rounded">
    Mint
  </button>
</template>

<script>
export default {
  name: 'MintButtonSmall',
}
</script>
