<template>
  <div class="w-40 h-10" @click="this.connect()" v-if="isLoaded">
    <button
      class="w-full h-full hover:bg-transparent bg-primary text-white font-semibold hover:text-primary py-2 px-4 border border-primary hover:border-secondary hover:border-transparent rounded">
      {{wallet ? trim(wallet) : 'Connect Wallet'}}
    </button>
  </div>
  <div class="flex flex-col justify-center items-center p-5" v-else>
    <span class="w-full h-full hover:bg-transparent bg-primary text-white font-semibold hover:text-primary py-2 px-4 border border-primary hover:border-secondary hover:border-transparent rounded">Accept on Metamask</span>
  </div>
</template>

<script>
import { connectMetamask } from "@/services/web3";
import {isMobile, smartTrim} from "@/services/util";
import { mapActions } from "vuex";
import store from "@/store";

export default {
  name: 'ConnectButtonSmall',
  data() {
    return {
      isLoaded: true,
    }
  },
  computed: {
    wallet() {
      return store.state.wallet;
    }
  },
  methods: {
    ...mapActions(["changeWallet"]),
    trim(wallet) {
      return smartTrim(wallet)
    },
    async connect() {
      this.isLoaded = false;

      if (isMobile()) {
        window.location = "https://metamask.app.link/dapp/sarugami.io";
      }

      let result = await connectMetamask()
      if (result.success) {
        this.changeWallet(result.msg)
      }
      this.isLoaded = true;
    }
  }
}
</script>

<style scoped>

</style>
