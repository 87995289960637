<template>
  <div class="flex flex-col justify-center items-center w-full pt-24 lg:pt-20">
    <div class="flex flex-row items-center justify-center text-lg bg-secondary px-10 py-2 text-white font-xs text-center border-gray-200 rounded-full">
      <ul class="flex flex-wrap -mb-px justify-center gap-2">
        <li class="lg:mr-2">
          <a href="#" class="inline-block px-4 poppins font-bold rounded-t-lg border-b-4 rounded border-transparent"
             :class="{'text-primary  border-primary': saruOn}"
             @click="saruOn = true">Sarugami Rarity</a>
        </li>
        <li class="lg:mr-2">
          <a href="#" class="inline-block px-4 poppins font-bold  rounded-t-lg border-b-4 rounded active border-transparent"
             :class="{'text-primary border-primary': !saruOn}" aria-current="page"
             @click="saruOn = false">Gama Rarity</a>
        </li>
      </ul>
    </div>
    <div class="flex flex-row justify-center items-center w-full">
      <SaruRarity v-if="saruOn"/>
      <GamaRarity v-if="!saruOn"/>
    </div>
  </div>
</template>

<script>

import SaruRarity from "@/components/rarity/SaruRarity";
import GamaRarity from "@/components/rarity/GamaRarity";
export default {
  name: "Rarity",
  components: { GamaRarity, SaruRarity },
  data() {
    return {
      saruOn: true
    }
  },
}
</script>

<style scoped>

</style>
