<template>
  <div class="flex flex-col justify-start items-center rounded-3xl w-full cursor-pointer" style="max-width: 300px">
    <div class="w-full text-right rounded-t-xl p-1 font-bold poppins"
         :class="{'bg-gold': rank <= 250,
         'bg-silver': rank >= 251 && rank <= 627,
         'bg-bronze': rank >= 628}">
      Rank #{{ rank }}
    </div>
    <img class="rounded-b-xl" :src="image + tokenId.split('#')[1] + '.png'">
    <span class="font-bold poppins">
      {{tokenId}}
    </span>
  </div>
</template>

<script>
export default {
  name: "SaruCard",
  props: ['rank', 'tokenId', 'image']
}
</script>

<style scoped>

</style>
