import Web3 from "web3";
import { saleOneShotAbi } from "@/assets/abis/saleOneShotAbi";
import {getHexProofMerkleTree} from "@/services/merkleTree";
import {whiteList} from "@/assets/walletsLists/whiteList";

export const walletMintCount = async (wallet) => {
  const web3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_PROVIDER_INFURA_URL));

  const contract = new web3.eth.Contract(saleOneShotAbi, process.env.VUE_APP_SALE_CONTRACT);
  return await contract.methods.walletCount(wallet).call();
}


export const mintHolder = async () => {}

export const mintWhitelist = async (wallet) => {
  try {
    const contract = new window.web3.eth.Contract(saleOneShotAbi, process.env.VUE_APP_SALE_CONTRACT);
    const merkleProof = getHexProofMerkleTree(wallet.toLowerCase(), whiteList)
    const method = contract.methods.mint(merkleProof);
    const gasPrice = await window.web3.eth.getGasPrice();

    const estimateGasResult = await method
      .estimateGas({
        from: wallet,
        gasPrice: gasPrice
      })
      .then(value => {
        return { success: true, gasEstimation: value };
      })
      .catch(error => {
        let jsonString = error.message.split("{");

        return {
          success: false,
          message: jsonString[0].replace("execution reverted: ", "").replace("Returned error: ", "")
        };
      });

    if (!estimateGasResult.success) {
      return estimateGasResult;
    }
    let returnResult = null;
    await method
      .send({
        from: wallet,
        gasPrice: gasPrice,
        gas: estimateGasResult.gasEstimation,
      })
      .on("confirmation", async () => {
        returnResult = {
          success: true,
          message: "Success",
        };
      })
      .on("error", async error => {
        let jsonString = error.message.split("{");
        returnResult = {
          success: false,
          message: jsonString[0].replace("execution reverted: ", "").replace("Returned error: ", ""),
        };
      });

    return returnResult;
  } catch (error) {
    let jsonString = error.message.split("{");
    return {
      success: false,
      message: jsonString[0].replace("execution reverted: ", "").replace("Returned error: ", ""),
    };
  }
}



