import Web3 from "web3";
import axios from "axios";
import { sale3Abi } from "@/assets/abis/sale3Abi";

export const connectMetamask = async () => { //getWeb3
  try {
    if (window.ethereum) {
      await window.ethereum.send('eth_requestAccounts');
      window.web3 = new Web3(window.ethereum);
      return {
        success: true,
        msg: await getAccount()
      };
    }

    return {
      success: false,
      msg: "You don't have Metamask plugin installed"
    };
  }
  catch (e) {
    if (e.message.includes("wallet_requestPermissions")) {
      return {
        success: false,
        msg: "You already have one solicitation on your Wallet"
      };
    }

    return {
      success: false,
      msg: "Something goes wrong!"
    };
  }
};

const getAccount = async () => {
  const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
  return accounts[0];
}

export const getIdsOwned = async (ownerAddress) => {
    let ids = [];

    if (!ownerAddress) {
      return ids;
    }

    const configsRequests = {headers: {"X-API-KEY": process.env.VUE_APP_API_TOKEN}};

    let url = process.env.VUE_APP_OPENSEA + '/assets?owner=' + ownerAddress +
      '&order_direction=desc&asset_contract_addresses='+ process.env.VUE_APP_SARUGAMI_CONTRACT + '&limit=50&include_orders=false'

    let response = await axios.get(url, configsRequests);
    console.log(response)
    if(response && response.data && response.data.assets){
      response.data.assets.forEach((asset) => {
        if(asset.token_id && Number(asset.token_id)){
          ids.push(asset.token_id)
        }
      })

      if(response.data.next){
        url = process.env.VUE_APP_OPENSEA + '/assets?owner=' + ownerAddress +
          '&order_direction=desc&asset_contract_addresses='+ process.env.VUE_APP_SARUGAMI_CONTRACT + '&limit=50&include_orders=false' + '&cursor=' + response.data.next;

        response = await axios.get(url, configsRequests);

        if(response && response.data && response.data.assets) {
          response.data.assets.forEach((asset) => {
            if (asset.token_id && Number(asset.token_id)) {
              ids.push(asset.token_id)
            }
          })
        }
      }

      if(response.data.next){
        url = process.env.VUE_APP_OPENSEA + '/assets?owner=' + ownerAddress +
          '&order_direction=desc&asset_contract_addresses='+ process.env.VUE_APP_SARUGAMI_CONTRACT + '&limit=50&include_orders=false' + '&cursor=' + response.data.next;

        response = await axios.get(url, configsRequests);

        if(response && response.data && response.data.assets) {
          response.data.assets.forEach((asset) => {
            if (asset.token_id && Number(asset.token_id)) {
              ids.push(asset.token_id)
            }
          })
        }
      }

      if(response.data.next){
        url = process.env.VUE_APP_OPENSEA + '/assets?owner=' + ownerAddress +
          '&order_direction=desc&asset_contract_addresses='+ process.env.VUE_APP_SARUGAMI_CONTRACT + '&limit=50&include_orders=false' + '&cursor=' + response.data.next;

        response = await axios.get(url, configsRequests);

        if(response && response.data && response.data.assets) {
          response.data.assets.forEach((asset) => {
            if (asset.token_id && Number(asset.token_id)) {
              ids.push(asset.token_id)
            }
          })
        }
      }

      if(response.data.next){
        url = process.env.VUE_APP_OPENSEA + '/assets?owner=' + ownerAddress +
          '&order_direction=desc&asset_contract_addresses='+ process.env.VUE_APP_SARUGAMI_CONTRACT + '&limit=50&include_orders=false' + '&cursor=' + response.data.next;

        response = await axios.get(url, configsRequests);

        if(response && response.data && response.data.assets) {
          response.data.assets.forEach((asset) => {
            if (asset.token_id && Number(asset.token_id)) {
              ids.push(asset.token_id)
            }
          })
        }
      }
    }

    console.log(ids)

    return await checkIdsClaimed(ids);
}


export const checkIdsClaimed = async (ids) => {

    const web3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_PROVIDER_INFURA_URL));

    const contract = new web3.eth.Contract(sale3Abi, process.env.VUE_APP_SALE_CONTRACT);
    let idsAvailable = [];

    for( let x=0; x<ids.length; x++) {
      let result = await contract.methods.nftsClaimed(ids[x]).call();

      if(!result){
        idsAvailable.push(Number(ids[x]))
      }
    }

    return idsAvailable;
}

export const grabIdNotClaimed = async () => {
  const web3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_PROVIDER_INFURA_URL));

  const contract = new web3.eth.Contract(sale3Abi, process.env.VUE_APP_FREE_MINT_CONTRACT);
  let idsAvailable = [];

  for( let x=0; x<=1049; x++) {
    let result = await contract.methods.nftsClaimed(x).call();
    console.log(x)
    if(!result){
      idsAvailable.push(x)
    }
  }

  console.log(idsAvailable)
  return idsAvailable;
}
