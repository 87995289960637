<template>
  <div class="flex flex-col justify-center items-center lg:items-start gap-5 lg:gap-8 w-full">
    <div class="flex flex-col lg:flex-row justify-center items-center lg:items-start w-full" v-if="!success && isLoaded">
      <div class="rounded-2xl justify-center items-center lg:items-start flex flex-row">
        <img style="height: 120px; width: 150px" class="rounded-2xl" src="../../assets/images/frogs/cast.jpg">
      </div>
      <div class="flex flex-col w-full justify-center items-start pl-3">
        <p class="whitespace-nowrap font-bold pt-4">SARUGAMI: GAMA SUMMONS</p>
        <p class="whitespace-nowrap poppins" style="color: #818181;">Holder Group</p>
        <p class="whitespace-nowrap poppins" style="color: #818181;">Price: FREE (*Gas only)</p>
        <p class="poppins" style="color: #818181;">You have {{countSarugamis.length}} Summons available to mint</p>
        <div class="flex w-full flex-row justify-between items-center" style="color: #979797" v-if="this.countSarugamis.length > 0">
          <div class="flex flex-row justify-center items-center gap-1 mr-5">
            <button class="w-7 h-7 flex flex-row justify-center items-center rounded font-bold"
                    style="background-color: #F1F1F1" @click="sub">-
            </button>
            <input type="number" v-model="qty" min="1"
                   class="poppins form-control text-xl text-center w-10 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
            <button class="w-7 h-7 flex flex-row justify-center items-center rounded font-bold"
                    style="background-color: #F1F1F1" @click="sum">+
            </button>
          </div>
        </div>
        <div class="poppins text-red-400 w-full" v-if="result && result.success === false">
          {{result.message}}
        </div>
      </div>
    </div>
    <div class="w-full" v-if="!success && isLoaded">
      <BuyButton @click="freeMint"  v-if="this.countSarugamis.length > 0"/>
    </div>
    <div class="flex w-full h-full flex-col justify-center items-center" v-if="!isLoaded">
      <div style="color: #979797">
        Connected wallet: {{ wallet }}
      </div>
      <div class="flex flex-col justify-center items-center p-5">
        <span class="font-bold poppins" style="color: #979797">Accept on Metamask</span>
        <div>
          <img class="rounded-2xl" style="width: 100px; height: 100px;" src="../../assets/images/loader.gif">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuyButton from "@/components/buttons/BuyButton";
import { mintHolder } from "@/services/sale";

export default {
  name: "HolderTab",
  components: { BuyButton },
  props: ['countSarugamis', 'wallet'],
  data: () => {
    return {
      qty: 1,
      result: null,
      success: false,
      isLoaded: true
    }
  },
  methods:{
    sum() {
      if(this.qty >= this.countSarugamis.length){
        return;
      }

      this.qty = this.qty + 1
    },
    sub() {
      if (this.qty - 1 < 1) {
        return 1;
      }

      this.qty = this.qty - 1
    },
    async freeMint() {
      if(this.qty > this.countSarugamis.length){
        this.result = {
          message: "Invalid quantity"
        }
        return;
      }
      this.isLoaded = false;
      const slicedArray = this.countSarugamis.slice(0, this.qty);

      this.result = await mintHolder(this.wallet, slicedArray)

      if (this.result.success) {
        this.success = true;
        this.$emit('changeSuccess')
      }

      this.isLoaded = true;
    },
  }
}
</script>

<style scoped>
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

</style>
