<template>
  <div class="flex flex-col items-center justify-center w-full h-full bgWavesGray"
       id="team">
    <div class="hidden lg:block flex flex-row items-center justify-center ">
      <div class="flex flex-row justify-center items-center ">
        <div class="flex flex-row justify-center items-center p-40">
          <div class="grid grid-cols-3 gap-10 items-center justify-center">
            <div class="bgMember flex flex-row items-center justify-center w-full h-full z-10"
                 v-for="(member, index) in members"
                 v-bind:key="index">
              <img :src="require('../../assets/images/team/ape'+ member.imageNumber +'.png')" class="w-96"
                   style="z-index: -1">
              <div class="invisible flex flex-col justify-center items-center absolute w-80 h-80 bg-white rounded-3xl">
                <p style="font-size: 22px; font-weight: 600">{{ member.name }}</p>
                <p style="font-size: 14px;">{{ member.sector }}</p>
                <a class="pt-5"
                   target="_blank"
                   :href="member.twitter">
                  <img src="../../assets/images/team/twitter.svg">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block lg:hidden w-screen h-full">
      <swiper
        slides-per-view="1.50"
        :modules="modules"
        centered-slides
        initial-slide="2"
        loop
        :space-between="20">
        <swiper-slide
          v-for="(member, index) in members"
          :key="index">
          <div class="flex flex-col items-center justify-center" style="height: 500px">
            <img :src="require('../../assets/images/team/ape'+ member.imageNumber +'.png')"
                 style="height: 230px; width: 260px; z-index: -1;">
            <div class="flex flex-col justify-center items-center">
              <p style="font-size: 22px; font-weight: 600">{{ member.name }}</p>
              <p style="font-size: 14px;">{{ member.sector }}</p>
              <a class="pt-5"
                 target="_blank"
                 :href="member.twitter">
                <img src="../../assets/images/team/twitter.svg">
              </a>
            </div>
          </div>

        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide,} from 'swiper/vue/swiper-vue';
import { Navigation } from 'swiper';
export default {
  name: "TeamSection",
  data: () => {
    return {
      members: [
        {
          name: "mono",
          sector: "Project Lead",
          twitter: "https://twitter.com/omegapoint",
          imageNumber: 2
        },
        {
          name: "DimDam",
          sector: "Community Manager",
          twitter: "https://twitter.com/_DimDam_",
          imageNumber: 1
        },
        {
          name: "Braianxde",
          sector: "Lead Dev",
          twitter: "https://twitter.com/Braian23",
          imageNumber: 4
        }
      ],
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>

<style scoped>

.bgMember:hover div {
  visibility: visible;
}
</style>