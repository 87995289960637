<template>
  <div class="flex flex-col justify-center items-center w-full h-full lg:pt-40 py-10">
    <div class="flex flex-row items-start justify-center w-full h-full 2k:w-10/12"
         id="roadmap">
      <div class="hidden lg:flex w-full h-full flex flex-row items-start 2k:items-center justify-center 2xl:px-52">
        <div class="flex flex-col justify-end items-end w-1/2 h-full">
          <img :src="require('../../assets/images/roadmap/m' + itemSelected.desc + '.png')" class="w-full">
        </div>
        <div class="flex flex-row justify-start items-start gap-5 w-1/2">
          <div class="flex flex-col justify-start items-start w-full">
            <div class="flex flex-row justify-start items-center gap-4">
              <span
                class="ibmPlex lg:text-7xl 2xl:text-8xl text-primary lg:pl-8 2xl:pl-24 4k:text-9xl font-bold flex items-center"
                style="height: 100px">ROADMAP</span>
              <div class="flex flex-col justify-center items-start">
            <span class="poppins 2k:text-xl 4k:text-3xl">
              {{ itemSelected.chapter }}
            </span>
                <span class="font-bold poppins 2k:text-2xl 4k:text-3xl">
              {{ itemSelected.tittle }}
            </span>
                <div class="bg-primary h-5 w-full rounded-3xl"></div>
              </div>
            </div>
            <div class="w-full px-5 py-10">
              <swiper
                slides-per-view="1"
                :modules="modules"
                @slideChange="changeSwiperIndex"
                centered-slides
                navigation>
                <swiper-slide
                  v-for="(item, index) in items"
                  :key="index">
                  <div class="flex flex-row justify-center items-center w-full">
                    <div class="w-10/12 poppins leading-7 lg:leading-10 2k:text-xl 4k:text-3xl ">
                      {{ item.text }}
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div class="block lg:hidden w-full h-full flex flex-col justify-center items-center gap-5">
        <span class="ibmPlex text-6xl font-bold text-primary">ROADMAP</span>
        <div class="flex flex-col justify-center items-center">
          <div class="flex flex-col justify-center items-center gap-1">
            <span class="poppins">
              {{ itemSelected.chapter }}
            </span>
            <span class="font-bold poppins font-medium">
              {{ itemSelected.tittle }}
            </span>
            <div class="bg-primary h-2.5 w-full rounded-3xl"></div>
          </div>
        </div>
        <div class="w-full h-full">
          <swiper
            slides-per-view="1"
            :modules="modules"
            @slideChange="changeSwiperIndex"
            centered-slides
            navigation>
            <swiper-slide
              v-for="(item, index) in items"
              :key="index">
              <div class="flex flex-row justify-center items-center w-full">
                <div class="w-11/12 poppins">
                  <img :src="require('../../assets/images/roadmap/m' + item.desc + '.png')">
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <span class="w-10/12 poppins text-quint text-sm text-center leading-7">
          {{ itemSelected.text }}
        </span>
      </div>
    </div>
    <img src="../../assets/images/backgrounds/yellow-banner.png" class="hidden lg:block">
    <img src="../../assets/images/backgrounds/yellow-banner-mobile.png" class="block lg:hidden pt-10">
  </div>


</template>

<script>

import { Swiper, SwiperSlide, } from 'swiper/vue/swiper-vue';
import { Navigation } from 'swiper';

export default {
  name: "RoadmapSection",
  data: () => {
    return {
      items: [
        {
          desc: "1",
          chapter: "Chapter 1",
          tittle: "Art & Community",
          text: "Through our love of art and storytelling, we aim to build a strong community.\n" +
            "Our genesis collection has nearly 500 traits, paying attention to the finest details. This eye-pleasing collection is what gets you to dive into the Sarugami universe and all its different characters. After all the support and love received from our community, the Sarugami team released a second collection free to all holders."
        },
        {
          desc: "2",
          chapter: "Chapter 2",
          tittle: "Adding Utility",
          text: "Passive staking will be added to the Gama Summons with the Sarugami genesis collection serving as a multiplier. Our currency, Kinsen, is an off-chain currency that will be used to acquire special editions, partner NFTs, and elixers to upgrade your Sarugami warriors."
        },
        {
          desc: "3",
          chapter: "Chapter 3",
          tittle: "Building Strength",
          text: "The NFT space was enamored with the allure of juicy prizes, both digital and physical: such as watches, cars, travels, NFT's etc..\n" +
            "We feel strongly that this space is about succeeding as a community. As the NFT market has taken a downturn, many projects have disappeared. Sarugami will persevere. We are diligently building during this time, preparing an entire system that will take flight as the market returns."
        },
        {
          desc: "4",
          chapter: "Chapter 4",
          tittle: "Moving from digital to physical",
          text: "Manga and other collectible items will be released.\n" +
            "Holder will be able to access digital versions just for being holders. In the future, some of these items will translate onto the physical market. Holders will be rewarded with this unique ability."
        },
        {
          desc: "5",
          chapter: "Chapter 5",
          tittle: "Sarugami Merchandise",
          text: "The creation of a clothing line, with high-quality designs and a short run, will allow us to allow our community to represent and expand Sarugami's brand into different industries."
        },
        {
          desc: "6",
          chapter: "Chapter 6",
          tittle: "Partnerships & Relationships",
          text: "Our organization's purpose is to use NFT technology to create a model that will operate in Web3, Web2, and the physical market.\n" +
            "With this in mind, we plan to collaborate with different companies in order to bring as much value as possible to the brand and our holders."
        },
        {
          desc: "7",
          chapter: "Season 2",
          tittle: "The future",
          text: "We are more than a roadmap, we are telling a story.\n" +
            "By expanding our team of artists and  contributors, we are ready to deliver a high-quality product in every aspect and create a successful business.\n" +
            "\n" +
            "With the help of our community and lots of hard work, we will take Sarugami to the next level!\n" +
            "This is just the beginning of a whole universe, and we can't wait to show you what we have planned for the future."
        }
      ],
      itemSelected: {
        desc: "1",
        chapter: "Chapter 1",
        tittle: "Art & Community",
        text: "Through our love of art and storytelling, we aim to build a strong community.\n" +
            "Our genesis collection has nearly 500 traits, paying attention to the finest details. This eye-pleasing collection is what gets you to dive into the Sarugami universe and all its different characters. After all the support and love received from our community, the Sarugami team released a second collection free to all holders."
      },
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    changeSwiperIndex(item) {
      this.itemSelected = this.items[item.activeIndex];
    }
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>

<style scoped
       lang="scss">
@import "./src/assets/css/variables";

</style>