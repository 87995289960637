export const whiteList = [
    "0x8941e58ca4240e26d46142a4a114e29a3b8249d9",
    "0xf97a52f80ec84480c72f3896c99dac51fca35940",
    "0x54d1e3094542a0d2910654168134b76d4e426934",
    "0xcbafdee55f89d30384e6180e683d1247bd0a70e5",
    "0x6b6065a8903906299552d6ed6358700aae1c3a5c",
    "0xfb88f94f9745d16ddec33d4f52e31e4b74e684f3",
    "0x435c47e410101937580189f15ae9a72af8b75819",
    "0xffef9c8c6e9e9689a40acbbc97b00b8bc02b6efb",
    "0x80de7f7aa93c8d03d63c3954465820634eae9950",
    "0x5184414b5e74dd5263188f38c3113ab43f4650d4",
    "0xd1a91d815f8889da26ccbd3c6dba7831606cfb03",
    "0xf8bc49ee2f15f89b11f84367d4fa89c24e2538a6",
    "0xbdc2f13a3d4c1852568fe95da71a88a7253eda51",
    "0x458b56f0845d25f57f5c3cf5de6960623110fee5",
    "0x80cfbf9a1d28059547f11316a5f48b7e058f2df1",
    "0x497222576c0a41575afe6d2c51f04af06f5923b2",
    "0x11469a1899e87230b0f7974a813dc56df93e0f5a",
    "0xbeda556f72a3845be500c9098f4e99082b6f1581",
    "0x1dde3dc7da8a3049b4182c0013712af4918d3864",
    "0x4590142d7bd0be34e46c0227e3610886b18b5797",
    "0x96e3c660466ef1a236fee6b1c7f81c9e93f53f2a",
    "0xe2502eb83f07244a5b5a5fa878bdbe9c8df07d93",
    "0xf8b9224c43b18239ab7cee79832ade3948a531b3",
    "0x01ffa88b743d70f480335700ed64475a60431adc",
    "0x519b8faf8b4ed711f4aa2b01aa1e3baf3b915ac9",
    "0xc5857cd4279a41558738d92595bd0d65c5a4f178",
    "0xd782e11393e3d5da7d1da996708be0274fdf5349",
    "0x2ca375552d86ebb36ca9b125169ac8f123f77ea7",
    "0x1f0050ae6d2c1fc39087a2c4fdb86fe408d358ce",
    "0xbbfd3fc1402963f61191190401e76957c6e02e83"
];