/**
 * Check if is Mobile
 * @returns {boolean}
 */
export const isMobile = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return true;
  } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return true;
  }
  return false;
};


export const smartTrim = (string) => {
  if (string) {
    let midpoint = Math.ceil(string.length / 2);
    let toremove = string.length - 8;
    let lstrip = Math.ceil(toremove / 2);
    let rstrip = toremove - lstrip;
    return string.substring(0, midpoint - lstrip) + '...'
      + string.substring(midpoint + rstrip);
  }

  return "";
}