<template>
    <div class="flex flex-col justify-center items-center w-full pt-24 lg:pt-20">
      <div class="flex flex-row items-center justify-center text-lg bg-secondary px-10 py-2 text-white font-xs text-center border-gray-200 rounded-full">
       
      </div>
      <div class="flex flex-row justify-center items-center w-full">
        <SaruRarity v-if="saruOn"/>
        <GamaRarity v-if="!saruOn"/>
      </div>
    </div>
  </template>
  
  <script>
  
  import Staking from "@/components/staking/Staking";
  export default {
    name: "Staking",
    Components: { Staking},
    data() {
      return {
        saruOn: true
      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  