<template>
  <div class="flex flex-col justify-center items-center lg:items-start gap-5 lg:gap-8 w-full">
    <div class="flex flex-col lg:flex-row justify-center lg:items-start items-center w-full" v-if="!success && isLoaded">
      <div class="rounded-2xl justify-center items-center lg:items-start flex flex-row">
        <img style="height: 120px; width: 150px" class="rounded-2xl" src="../../assets/images/frogs/pray.jpg">
      </div>
      <div class="flex flex-col w-full justify-center items-start pl-3">
        <p class="whitespace-nowrap font-bold pt-4">SARUGAMI: GAMA SUMMONS</p>
        <p class="whitespace-nowrap poppins" style="color: #818181;">PUBLIC</p>
        <p v-if="!claimed" class="poppins" style="color: #818181;">You have 1 Summon available to mint</p>
        <p class="whitespace-nowrap poppins" style="color: #818181;">Price: FREE (*Gas only)</p>
        <p v-if="claimed" class="poppins" style="color: #818181;">Max per wallet reached!</p>
        <div class="poppins text-red-400 w-full" v-if="result && result.success === false">
          {{result.message}}
        </div>
      </div>
    </div>
    <div class="w-full" v-if="!claimed && !success && isLoaded">
      <BuyButton @click="freeMint"/>
    </div>
    <div class="flex w-full h-full flex-col justify-center items-center" v-if="!isLoaded">
      <div style="color: #979797">
        Connected wallet: {{ wallet }}
      </div>
      <div class="flex flex-col justify-center items-center p-5">
        <span class="font-bold poppins" style="color: #979797">Accept on Metamask</span>
        <div>
          <img class="rounded-2xl" style="width: 100px; height: 100px;" src="../../assets/images/loader.gif">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mintWhitelist, walletMintCount } from "@/services/sale";
import BuyButton from "@/components/buttons/BuyButton";
import { getDataWallet } from "@/services/proxy";
import store from "@/store";

export default {
  name: "WhitelistTab",
  components: { BuyButton },
  data: () => {
    return {
      qty: 1,
      result: null,
      success: false,
      group: null,
      claimed: true,
      isLoaded: true,
      timestamp: process.env.VUE_APP_START_WHITELIST
    }
  },
  props: ['wallet'],
  async mounted() {
    this.group = getDataWallet(this.wallet)
    this.claimed = await walletMintCount(this.wallet)
  },
  methods:{
    changeWhitelist() {},
    async freeMint() {
      this.isLoaded = false;
      this.result = await mintWhitelist(this.wallet)
      this.claimed = walletMintCount(this.wallet)

      if (this.result.success) {
        this.success = true;
        this.$emit('changeSuccess')
      }

      this.isLoaded = true;
    },
  },
  watch: {
    async wallet() {
      this.group = getDataWallet(this.wallet)
      this.claimed = await walletMintCount(this.wallet)
      return store.state.wallet;
    }
  }
}
</script>

<style scoped>
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

</style>
