<template>
  <div class="flex flex-col w-full justify-center items-center" >
    <MainSection/>
    <VisionSection style="z-index:1"/>
    <RoadmapSection/>
    <TeamSection/>
  </div>
</template>

<script>

import MainSection from "@/components/homepage/MainSection";
import VisionSection from "@/components/homepage/VisionSection";
import RoadmapSection from "@/components/homepage/RoadmapSection";
import TeamSection from "@/components/homepage/TeamSection";
export default {
  name: 'Home',
  components: {TeamSection, RoadmapSection, VisionSection, MainSection},
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>