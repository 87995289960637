<template>
  <div class="modal-backdrop z-20" v-if="sarugami">
    <div
      class="bg-white border-rarity border-2 rounded-xl flex flex-col justify-start items-center w-12/12 h-5/6 lg:w-3/6 lg:h-5/6 2xl:w-2/6 2xl:h-4/6 p-4 overflow-auto">
      <div class="flex flex-row items-center justify-between w-full">
        <div class="text-2xl font-bold poppins">Rarity Rank #{{sarugami.rank}}</div>
        <div class=""
             @click="changeIsModalRarityOpen(false)">
          <img class="cursor-pointer"
               src="../../assets/images/rarity/x.svg"
               style="height: 38px; width: 38px">
        </div>
      </div>
      <div class="flex lg:flex-row flex-col justify-start lg:items-start items-center pt-5">
        <div class="lg:w-3/5 w-full">
          <div v-if="sarugami.name.split('#')[0] === 'Sarugami '">
          <img class="rounded-t-xl" :src="sarugami.image ? sarugami.image : sarugami.image_url" v-if="sarugami.image_url && sarugami.image_url.split('.')[4] !== 'mp4'">
          <video autoplay muted :src="sarugami.image ? sarugami.image : sarugami.image_url" v-else></video>
          </div>
          <div v-else>
            <img class="rounded-t-xl" :src="sarugami.image">
          </div>
          <div class="bg-gray-50 flex flex-row justify-evenly items-center p-1" v-if="sarugami.name.split('#')[0] === 'Sarugami '">
            <div class="flex flex-row justify-center items-center gap-2">
              <img src="../../assets/images/rarity/sword.svg" style="width: 30px; height: 30px">
              <span class="text-lg font-bold">{{ atk }}</span>
            </div>
            <div class="flex flex-row justify-center items-center gap-2">
              <img src="../../assets/images/rarity/shield.svg" style="width: 30px; height: 30px">
              <span class="text-lg font-bold">{{ def }}</span>
            </div>
            <div class="flex flex-row justify-center items-center gap-2">
              <img src="../../assets/images/rarity/health.svg" style="width: 30px; height: 30px">
              <span class="text-lg font-bold">{{ hp }}</span>
            </div>
          </div>
          <div class="flex flex-row justify-between items-center py-3">
            <span class="text-xl font-bold poppins">{{sarugami.name}}</span>
            <a v-if="sarugami.name.split('#')[0] === 'Sarugami '" :href="'https://opensea.io/assets/ethereum/0x210f75e4ebedf6f19dda89950fa7278030ec4ca3/' + sarugami.name.split('#')[1]" target="_blank">
              <img src="../../assets/images/rarity/opensea.svg">
            </a>
            <a :href="'https://opensea.io/assets/ethereum/0xc36b5efdcc6abd1b4f3c35649a9b3b9e5364347f/' + sarugami.name.split('#')[1]" target="_blank" v-else>
              <img src="../../assets/images/rarity/opensea.svg">
            </a>
          </div>
        </div>
        <div class="flex flex-col justify-start items-start lg:w-2/5 w-full h-full lg:pl-6 gap-2">
          <span class="text-lg font-bold poppins pb-2">Rarity Score: {{ parseFloat(sarugami.rarity).toFixed(2) }}</span>
          <div class="flex flex-col items-start justify-start w-full cursor-pointer" v-for="attr in sarugami.attributes" :key="attr" @click="applyProperty(attr.trait_type, attr.value, true)">
            <span v-if="attr.trait_type !== 'ATK' && attr.trait_type !== 'DEF' && attr.trait_type !== 'HP'">{{ attr.trait_type }}</span>
            <div v-if="attr.trait_type !== 'ATK' && attr.trait_type !== 'DEF' && attr.trait_type !== 'HP'" class="flex flex-row items-start justify-between border border-gray-400 w-full rounded p-0.5 2xl:p-1">
              <span>{{ attr.value }}</span>
              <span>{{ attr.qty }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ModalRarity",
  data: () => {
    return {
      atk: 0,
      def: 0,
      hp: 0
    }
  },
  props: ['sarugami'],
  methods: {
    ...mapActions(["changeIsModalRarityOpen"]),
    applyProperty(a, b) {
      this.$emit('applyProperty', a, b, true)
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.changeIsModalRarityOpen(false)
    }
  },
  watch: {
    async sarugami() {
      this.sarugami.attributes.forEach((attr) => {
        if(attr.trait_type === 'ATK'){
          this.atk = attr.value;
        }
        if(attr.trait_type === 'DEF'){
          this.def = attr.value;
        }
        if(attr.trait_type === 'HP'){
          this.hp = attr.value;
        }
      })
    }
  }
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(239, 220, 220, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.buttonMetamask {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 10px 30px 10px 30px;
  background: linear-gradient(90deg, #958DFF 0%, #8CC6FF 50.34%, #8AE6B6 100%);
  border-radius: 40px;
}
</style>
