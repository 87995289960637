import MerkleTree from 'merkletreejs'
import keccak256 from 'keccak256'


export const getHexProofMerkleTree = (wallet, leafs) => {
  const merkleTree = new MerkleTree(leafs, keccak256, {
    hashLeaves: true, sortPairs: true
  });

  return merkleTree.getHexProof(keccak256(wallet))
}

export const getMerkleHexRoot = (leafs) => {
  const merkleTree = new MerkleTree(leafs, keccak256, {
    hashLeaves: true, sortPairs: true
  });

  return merkleTree.getHexRoot()
}