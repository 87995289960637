import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '../src/assets/css/index.scss';
import '../src/assets/css/burger-menu.scss';
import "tailwindcss/tailwind.css";
import 'swiper/swiper-bundle.css';

createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
