<template>
  <div class="modal-backdrop z-20">
    <div
      class="bg-white rounded-xl flex flex-col justify-center items-center w-full h-5/6 lg:w-3/6 lg:h-4/6 2xl:w-2/6 2xl:h-3/6">
      <div class="flex rounded-t-xl flex-row items-end justify-end w-full bg-secondary">
        <div class="m-3"
             @click="changeIsModalMintOpen(false)">
          <img class="cursor-pointer"
               src="../../assets/images/Union.svg"
               style="height: 13px; width: 28px">
        </div>
      </div>
      <div
        class="pb-5 px-20 text-lg bg-secondary font-xs text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700 w-full">
        <ul class="flex flex-wrap -mb-px">
          <li class="lg:mr-2">
            <a href="#" class="inline-block px-4 poppins font-bold rounded-t-lg border-b-4 rounded border-transparent"
               :class="{'text-primary  border-primary': whitelistTab}"
               @click="whitelistTab = true">Public</a>
          </li>
          <li class="lg:mr-2">
            <a href="#" class="inline-block px-4 poppins font-bold  rounded-t-lg border-b-4 rounded active border-transparent"
               :class="{'text-primary border-primary': !whitelistTab}" aria-current="page"
               @click="whitelistTab = false">Holder</a>
          </li>
        </ul>
      </div>
      <div class="w-10 h-10 absolute left-0 top-0 z-30" @click="checkDup()"/>
      <div class="w-10 h-10 absolute left-0 bottom-0 z-30" @click="getRoots()"/>
      <div
        class="h-full flex flex-col items-center lg:justify-start justify-center gap-9 lg:w-9/12 m-2 poppins font-bold"
        v-if="isWrongChain && wallet">
        Connect to Mainnet Ethereum network
      </div>
      <ConnectButton v-if="!wallet"/>
      <div class="h-full flex flex-col items-center lg:justify-start justify-center gap-5 w-full lg:w-11/12 m-2 bg-white"
           v-if="!isWrongChain">
        <div class="flex flex-col w-full justify-center items-center gap-2 w-full bg-white"
             v-if="wallet && isLoaded">
          <div class="flex flex-col justify-center items-center gap-5 lg:gap-8">
            <div class="w-full h-full bg-white p-8 rounded-b-2xl" style="max-width: 550px;"  v-if="!this.success">
              <HolderTab v-if="!whitelistTab" :wallet="wallet" :countSarugamis="countSarugamis"
                         @changeSuccess="changeSuccess"/>
              <WhitelistTab :wallet="wallet" @changeSuccess="changeSuccess" v-else/>
            </div>
          </div>
        </div>
        <div class="flex w-full h-full m-4 flex-col justify-start items-center text-center bg-white  p-4 rounded-b-2xl" v-if="this.success">
          <div class="ibmPlex text-2xl font-bold">
            CONGRATULATIONS
          </div>
          <div class="font-bold pb-2" style="color: #979797">
            You got a Gama Summon(s)!
          </div>
          <div class="flex flex-col justify-center items-center gap-3">
            <div class="flex flex-row justify-center items-center gap-2" style="color: #979797">
              <img src="../../assets/images/opensea-blue.svg" width="30" height="39"/>
              <span class="poppins">
              Check on <a class="font-bold" href="https://opensea.io/collection/sarugami-gama-summons"
                          target="_blank">Opensea</a></span>
            </div>
            <div class="flex flex-row justify-center items-center gap-2" style="color: #979797">
              <img src="../../assets/images/twitter-blue.svg" width="30" height="39"/>
              <span class="poppins"><a class="font-bold cursor-pointer" @click="openTwitter">Share </a> with your friends</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ConnectButton from "@/components/buttons/ConnectButton";
import store from "@/store";
import { checkDuplicates, getMerkleRoots } from "@/services/proxy";
import { smartTrim } from "@/services/util";
import { getIdsOwned } from "@/services/web3";
import HolderTab from "@/components/tabsMint/HolderTab";
import WhitelistTab from "@/components/tabsMint/WhitelistTab";

export default {
  name: "MintModal",
  components: { WhitelistTab, HolderTab, ConnectButton },
  data: () => {
    return {
      whitelistTab: true,
      infoGroup: null,
      qtyMinted: 0,
      result: null,
      qty: 1,
      success: false,
      qtyMintedHolder: 1,
      isHolder: false,
      isLoaded: true,
      isWrongChain: true,
      countSarugamis: []
    }
  },
  async mounted() {
    await this.isOnRightNetwork();
    getMerkleRoots()
  },
  methods: {
    ...mapActions(["changeIsModalMintOpen"]),
    trim(wallet) {
      return smartTrim(wallet)
    },
    checkDup() {
      checkDuplicates()
    },
    getRoots() {
      getMerkleRoots()
    },
    openTwitter() {
      let text = "I've just minted my Gama Summon from @SarugamiNFT. Check it out!";
      let url = "https://sarugami.io";

      window.open(`https://twitter.com/share?text=${text}&url=${url}`)
    },
    changeSuccess() {
      this.success = true;
    },
    async isOnRightNetwork() {
      if (window.ethereum) {
        window.ethereum.on('chainChanged', async () => {
          await this.isOnRightNetwork();
        });

        const currentChainId = await window.ethereum.request({
          method: 'eth_chainId',
        });
        if (currentChainId === "0x1") {
          this.isWrongChain = false;
          return;
        }

        this.isWrongChain = true;
      }
    }
  },
  computed: {
    wallet() {
      return store.state.wallet;
    }
  },
  watch: {
    async wallet() {
      this.countSarugamis = await getIdsOwned(store.state.wallet);

      return store.state.wallet;
    }
  }
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>
