import { whiteList } from "@/assets/walletsLists/whiteList";
import { alphaList } from "@/assets/walletsLists/alphaList";
import { getMerkleHexRoot } from "@/services/merkleTree";

export const getDataWallet = (wallet) => {
  return getGroupInfoByWallet(wallet);
}

const getGroupInfoByWallet = (wallet) => {
    const isAlphaList = alphaList.includes(wallet.toLowerCase());

    if (isAlphaList) {
      return {
        num: 2,
        list: alphaList,
        maxQty: 3,
        price: 0,
        name: "Alpha Whitelist"
      };
    }

  const isWhiteList = whiteList.includes(wallet.toLowerCase());

  if (isWhiteList) {
    return {
      num: 1,
      list: whiteList,
      maxQty: 4,
      price: 0,
      name: "Regular Whitelist"
    };
  }

  return null;
}

export const isWhitelisted = (wallet) => {
  return whiteList.includes(wallet.toLowerCase());
}

export const checkDuplicates = () => {
  const arrayFull = [...whiteList, ...alphaList]

  let sorted_arr = arrayFull.slice().sort(); // You can define the comparing function here.
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] == sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  console.log(results)
}

export const getMerkleRoots = () => {
  console.log('whitelist root: ' + getMerkleHexRoot(whiteList))
}
