<template>
  <div class="flex flex-col justify-center items-center w-full">
    <div class="flex flex-col lg:flex-row justify-between p-3 w-full gap-4">
      <div class="flex flex-row lg:justify-center justify-start items-center gap-4">
        <img :src="showFilter ? require('../../assets/images/rarity/menu.svg') : require('../../assets/images/rarity/arrow-left.svg')" width="50" height="50" class="cursor-pointer" @click="openCloseNav()"/>
        <span class="poppins font-bold text-3xl ">Sarugami Rarity</span>
      </div>
      <div class="flex flex-row justify-end items-center gap-2">
        <span>Sort By:</span>
        <div class="flex flex-row justify-center items-center border border-black rounded-3xl w-60">
        <span class="flex flex-grow items-center justify-center border-r border-black rounded-l-3xl cursor-pointer"
              :class="{'bg-rarity': this.sortByRanking}" @click="sortBy(1)">Rarity</span>
          <span class="flex flex-grow items-center justify-center border-l border-black rounded-r-3xl cursor-pointer"
                :class="{'bg-rarity': !this.sortByRanking}"
                @click="sortBy(0)">&nbsp;&nbsp;ID&nbsp;&nbsp;</span>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-start items-start w-full">
      <div class="flex flex-col justify-start items-start w-full my-8 sidebar border-rarity rounded ml-3 h-full"
           style="max-width: 370px" id="mySidebar" :class="{'border' : !showFilter}">
        <span class="text-2xl p-2 poppins">Filters</span>
        <div class="flex flex-col justify-start items-start p-2 w-full">
          <span class="text-lg font-bold poppins">ID SEARCH</span>
          <div class="flex flex-row items-start gap-4 w-full">
            <div class="flex flex-col justify-start items-start">
              <span>Sarugami ID</span>
              <input type="text" max="2510" v-model="idSearch" min="0" class="w-20 border border-gray-400 rounded h-9">
            </div>
          </div>
          <button class="border border-gray-400 py-1 px-4 rounded mt-4" @click="applySarugamiId">
            Search
          </button>
        </div>
        <div class="flex flex-col justify-start items-start p-2 gap-2 w-full">
          <span class="flex flex-row justify-between items-center text-lg font-bold poppins w-full">PROPERTIES <button class="border border-gray-400 px-1 rounded" @click="cleanFilters">
            Clear All
          </button></span>
          <div class="flex flex-col justify-start items-start pt-2 gap-2 w-full">
            <div class="flex flex-col justify-between items-center p-2 border-gray-400 border w-full rounded"
                 v-for="property in properties" :key="property">
              <div class="flex flex-row justify-between items-center w-full accordion">
                <span class="poppins">{{ property.name }}</span>
                <img src="../../assets/images/rarity/arrow-down.svg">
              </div>
              <div class="flex flex-row justify-start items-center w-full panel">
                <div class="flex flex-row justify-start items-center gap-2" v-for="trait in property.items"
                     :key="trait">
                  <input class="check_box" type="checkbox" :id="property.name + trait.toString().replace(' ', '')"
                         @click="this.applyProperty(property.name, trait)">
                  <label class="cursor-pointer" :for="property.name + trait.toString().replace(' ', '')"/>
                  <span>{{ trait }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto flex flex-wrap my-3 justify-center items-center max-w-content p-5 w-full" id="main">
        <div class="grid lg:grid-cols-5 sm:grid-cols-3 grid-cols-1 gap-4 w-full">
          <SaruCard :rank="saru.rank" :tokenId="saru.name" v-for="saru in sarugamis" :key="saru" @click="changeIsModalRarityOpen(true); saruSelected = saru" image="https://srgm.nyc3.digitaloceanspaces.com/optimized/"/>
        </div>
        <button @click="getMoreResults()" class="border border-gray-400 py-1 px-4 rounded mt-4" v-if="sarugamis.length > 0">More</button>
        <div v-else>
          No results! Check the filters!
        </div>
      </div>
    </div>
    <ModalRarity v-show="isModalRarityOpen" :sarugami="saruSelected" @applyProperty="applyProperty"/>
  </div>
</template>

<script>

import SaruCard from "@/components/SaruCard";
import saru from "../../assets/images/rarity/jsons/saru.json"
import filters from "../../assets/images/rarity/jsons/filtersSaru.json"
import { isMobile } from "@/services/util";
import { mapActions } from "vuex";
import store from "@/store";
import ModalRarity from "@/components/modals/ModalRarity";export default {
  name: "SaruRarity",
  components: { ModalRarity, SaruCard },
  data() {
    return {
      sortByRanking: true,
      showFilter: false,
      idSearch: null,
      properties: filters,
      listFilters: [],
      sarugamis: [],
      saruFile: saru,
      saruBackup: saru,
      index: 20,
      filteredResults: [],
      isMobile: isMobile(),
      saruSelected: null
    }
  },
  computed: {
    isModalRarityOpen() {
      return store.state.isModalRarityOpen;
    }
  },
  mounted() {
    let acc = document.getElementsByClassName("accordion");
    let i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        /* Toggle between adding and removing the "active" class,
         to highlight the button that controls the panel */
        this.classList.toggle("active");
        let elementChildren = this.querySelectorAll('img');
        elementChildren[0].classList.toggle('transform')
        elementChildren[0].classList.toggle('rotate-180')
        /* Toggle between hiding and showing the active panel */
        let panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
    //this.getFilters();
    if(this.isMobile){
      console.log('zaaa')
      this.openCloseNav()
    }
    this.sarugamis = saru.slice(0, 20);
  },
  methods: {
    ...isMobile(),
    ...mapActions(["changeIsModalRarityOpen"]),
    cleanFilters() {
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
      this.idSearch = null;
      this.sarugamis = saru.slice(0, 20);
      this.index = 20;
    },
    getMoreResults() {
      if (this.filteredResults.length > 0) {
        this.sarugamis = this.sarugamis.concat(this.filteredResults.slice(this.index, this.index + 20));
      } else {
        this.sarugamis = this.sarugamis.concat(saru.slice(this.index, this.index + 20));
      }

      this.index = this.index + 20;
    },
    applySarugamiId() {
      let search = this.idSearch;
      this.cleanFilters()
      this.index = 0;


      saru.forEach((saru) => {
        if(saru.name.split('#')[1].toString() === search.toString()){
          this.saruSelected = saru;
          this.changeIsModalRarityOpen(true)
        }
      });
    },
    applyProperty(traitType, value, fromModal) {
      let checkbox = document.getElementById(traitType + value.toString().replace(' ', ''))

      if(fromModal){
        checkbox.checked = true;
      }

      let isChecked = checkbox.checked;
      if (isChecked) {
        this.listFilters.push({ traitType: traitType, value: value })
      } else {
        this.listFilters = this.listFilters.filter(function (el) {
          return (el.value !== value || el.traitType !== traitType)
        });
      }

      let searchBase = saru;
      let newSearchFiltered = [];

      if (!this.filteredResults) {
        searchBase = this.filteredResults;
      }

      searchBase.forEach((sa) => {
        let filtersMatch = 0;

        sa.attributes.forEach((trait) => {
          this.listFilters.forEach((filterItem) => {
              if (trait.trait_type === filterItem.traitType && trait.value === filterItem.value) {
                filtersMatch ++;
              }
            }
          )
        })
        if(filtersMatch === this.listFilters.length){
          newSearchFiltered.push(sa);
        }
      });

      this.filteredResults = [
        ...new Map(newSearchFiltered.map(item =>
          [
            item['name'],
            item
          ])).values()
      ];

      if(this.listFilters.length === 0){
        this.sarugamis = saru.slice(0, 20);
        this.index = 20;
        return;
      }

      this.filteredResults = newSearchFiltered;
      this.sarugamis = this.filteredResults.slice(0, 20);
      this.index = 20;
    },
    sortBy(mode) {
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);

      if (mode) {
        this.sortByRanking = true;
        console.log(saru)
        saru.sort(function (a, b) {
          if (a.rank < b.rank) { return -1; }
          if (a.rank > b.rank) { return 1; }
          return 0;
        })


        this.index = 0;
        this.sarugamis = saru.slice(0, 20);
      } else {
        saru.sort(function (a, b) {
          if (Number(a.name.split('#')[1]) < Number(b.name.split('#')[1])) { return -1; }
          if (Number(a.name.split('#')[1]) > Number(b.name.split('#')[1])) { return 1; }
          return 0;
        })
        this.index = 20;
        this.sarugamis = saru.slice(0, 20);
        this.sortByRanking = false;
        console.log(saru)
      }
    },
    getFilters() {
      let properties = []
      let countProperties = [];
      properties['1/1'] = []
      properties['Legendary'] = []

      saru[0]['attributes'].forEach((saru) => {
        properties[saru.trait_type] = [];
      })

      saru.forEach((saru) => {
        saru.attributes.forEach((trait) => {
          if (!countProperties[trait.trait_type + trait.value]) {
            countProperties[trait.trait_type + trait.value] = 1;
          } else {
            countProperties[trait.trait_type + trait.value] += 1;
          }

          if (!properties[trait.trait_type].includes(trait.value)) {
            properties[trait.trait_type].push(trait.value)
            properties[trait.trait_type].sort(function (a, b) {
              if (a < b) { return -1; }
              if (a > b) { return 1; }
              return 0;
            })
          }
        })
      })

      let filters = []
      Object.keys(properties).forEach(function (key) {
        filters.push({ name: key, items: properties[key] })
      });

      saru.forEach((saru) => {
        let rarity = 0;
        saru.attributes.forEach((trait) => {
          if (trait.trait_type !== "ATK" && trait.trait_type !== "DEF" && trait.trait_type !== "HP") {
            rarity +=  1 / (countProperties[trait.trait_type + trait.value] / 2510)
            trait.qty = countProperties[trait.trait_type + trait.value];
            if(trait.trait_type === "Legendary"){
              rarity += 10000;
            }

            if(trait.trait_type === "1/1"){
              rarity += 20000;
            }
          }
        })

        saru.rarity = rarity;
      })

      saru.sort(function (a, b) {
        if (a.rarity > b.rarity) { return -1; }
        if (a.rarity < b.rarity) { return 1; }
        return 0;
      })

      saru.forEach((saru, index) => {
        saru.rank = index+1;
      });

      console.log(saru)
    },
    openCloseNav() {
      if (this.showFilter) {
        document.getElementById("mySidebar").style.width = "390px";
        if(isMobile()) {
          document.getElementById("main").style.display = "none";
        }
      } else {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("main").style.display = "block";
      }

      this.showFilter = !this.showFilter;
    }
  }
}
</script>

<style scoped>
#main {
  transition: margin-left .5s;
}

.sidebar {
  z-index: 1;
  overflow-x: hidden;
  transition: 0.5s;
}

.accordion {
  cursor: pointer;
  transition: 0.4s;
}

.panel {
  display: none;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.check_box {
  display:none;
}

.check_box + label{
  background:url('../../assets/images/rarity/uncheked.png') no-repeat;
  background-size: cover;
  height: 32px;
  width: 32px;
  display:inline-block;
  padding: 0 0 0 0px;
}

.check_box:checked + label{
  background:url('../../assets/images/rarity/checked.png') no-repeat;
  background-size: cover;
  height: 32px;
  width: 32px;
  display:inline-block;
  padding: 0 0 0 0px;
}
</style>
