<template>
  <div class="w-40 h-10" @click="this.connect()" v-if="isLoaded">
    <button
      class="w-full h-full hover:bg-transparent bg-primary text-white font-semibold hover:text-primary py-2 px-4 border border-primary hover:border-secondary hover:border-transparent rounded">
      Connect Wallet
    </button>
  </div>
  <div class="flex flex-col justify-center items-center p-5" v-else>
    <span class="text-white font-bold poppins">Accept on Metamask</span>
    <div>
      <img class="rounded-2xl" src="../../assets/images/loader.gif">
    </div>
  </div>
</template>

<script>
import { connectMetamask } from "@/services/web3";
import { isMobile } from "@/services/util";
import { mapActions } from "vuex";

export default {
  name: 'ConnectButton',
  data() {
    return {
      isLoaded: true,
    }
  },
  methods: {
    ...mapActions(["changeWallet"]),
    async connect() {
      this.isLoaded = false;

      if (isMobile()) {
        window.location = "https://metamask.app.link/dapp/sarugami.io";
      }

      let result = await connectMetamask()
      if (result.success) {
        this.changeWallet(result.msg)
      }
      this.isLoaded = true;
    }
  }
}
</script>

<style scoped>

</style>
