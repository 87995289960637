<template>
  <div class="flex flex-col w-full justify-center items-center bgWavesGray bg-white pt-32 4k:pt-64">
    <div class="flex flex-col w-full 2xl:w-5/6 justify-start items-center gap-10 2k:w-4/6">
      <div class="flex flex-row justify-between items-start w-full lg:px-40 px-10">
        <div class="flex flex-col justify-start items-start w-full">
          <span class="ibmPlex text-6xl font-bold text-primary">LORE</span>
          <span class="poppins text-lg 2k:text-xl 4k:text-3xl">Season 1</span>
        </div>
        <div class="ibmPlex text-black whitespace-no-wrap lg:block hidden">
          SARUGAMI&nbsp;&nbsp;&nbsp;猿&nbsp;神
        </div>
      </div>
      <div class="flex flex-col justify-start items-start gap-10 pb-40 lg:px-40">
        <div class="flex flex-col lg:flex-row justify-between items-center 2xl:gap-40 lg:gap-20 gap-6">
          <div class="flex flex-col justify-start items-start lg:w-1/2">
            <div class="pl-10 lg:pl-0 z-0">
              <span class="poppins font-bold 2k:text-xl 4k:text-3xl">Doruta</span>
              <div class="lineYellow">&nbsp;</div>
            </div>
            <img src="../assets/images/lore/Doruta1.png" class="-mt-2">
          </div>
          <div class="poppins leading-9 lg:w-1/2 lg:ml-5 px-2 2k:text-xl 4k:text-3xl">
            On the aftermath of a bloody battle, Doruta, the yellow Sarugami child, finds himself alone, without a
            memory to hold himself onto while facing an unpredictable future.
          </div>
        </div>
        <div
          class="flex flex-col lg:flex-row-reverse justify-between items-center 2xl:gap-40 lg:gap-20 gap-6">
          <div class="flex flex-col justify-start lg:items-start items-end lg:w-1/2">
            <div class="pr-10 lg:pr-0 z-0">
              <span class="poppins font-bold 2k:text-xl 4k:text-3xl">Dorigo</span>
              <div class="lineYellow">&nbsp;</div>
            </div>
            <img src="../assets/images/lore/Dorigo1.png" class="-mt-2">
          </div>
          <div class="poppins leading-9 lg:w-1/2 lg:ml-5 px-2 2k:text-xl 4k:text-3xl">
            Born in a cradle full of wealth and loneliness, a child raised by a family of wealthy warlords in a period
            of power transition,Dorigo, the blue sarugami kid, has only his sword and himself in times of uncertainty
            and fear.
          </div>
        </div>
        <div class="flex flex-col lg:flex-row justify-between items-center 2xl:gap-40 lg:gap-20 gap-6">
          <div class="flex flex-col justify-start items-start lg:w-1/2">
            <div class="pl-10 lg:pl-0 z-0">
              <span class="poppins font-bold 2k:text-xl 4k:text-3xl">Doruta</span>
              <div class="lineYellow">&nbsp;</div>
            </div>
            <img src="../assets/images/lore/Doruta2.png" class="-mt-2">
          </div>
          <div class="poppins leading-9 lg:w-1/2 lg:ml-5 px-2 2k:text-xl 4k:text-3xl">
            Without the ability to stand still while facing injustice combined with the true joy of physical combat,
            Doruta grew strong in the Sarugami orphanage, going through all sorts of hardships while always showing a
            bright smile.
          </div>
        </div>
        <div
          class="flex flex-col lg:flex-row-reverse justify-between items-center 2xl:gap-40 lg:gap-20 gap-6">
          <div class="flex flex-col justify-start lg:items-start items-end lg:w-1/2">
            <div class="pr-10 lg:pr-0 z-0">
              <span class="poppins font-bold 2k:text-xl 4k:text-3xl">Dorigo</span>
              <div class="lineYellow">&nbsp;</div>
            </div>
            <img src="../assets/images/lore/Dorigo2.png" class="-mt-2">
          </div>
          <div class="poppins leading-9 lg:w-1/2 lg:ml-5 px-2 2k:text-xl 4k:text-3xl">
            Dorigo was raised to be the Warlord’s successor and perpetuate his family's influence over the affairs of
            the land. With daily training in swordplay, writing, and poetry, he would soon be able to fit his parents'
            shoes perfectly.
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary bgWavesGray flex flex-col justify-center items-center gap-20 pt-20 w-full">
      <div class="ibmPlex text-primary lg:text-6xl text-4xl font-bold">ANIMATED SERIES</div>
      <video class="lg:w-1/2 w-full p-5 lg:p-0" src="../assets/videos/video-intro.mp4" controls/>
      <div class="poppins leading-9 lg:w-1/2 ml-5 text-quaternary 2k:text-xl 4k:text-3xl">
        Two different paths, destined to cross.
        Doruta and Dorigo will together unveil the secrets of this land. But it won't be that easy...
      </div>
      <img src="../assets/images/backgrounds/yellow-banner.png" class="hidden lg:block">
      <img src="../assets/images/backgrounds/yellow-banner-mobile.png" class="block lg:hidden pt-10">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {}
  }
}
</script>

<style scoped
       lang="scss">
@import "./src/assets/css/variables";

.lineYellow {
  z-index: 1;
  border-top: 15px solid $primary;
  border-radius: 14px;
  width: 70px;
  height: 13px;
}

@media screen and (min-width: 2000px) {
  .lineYellow {
    width: 70px;
  }
}

@media screen and (min-width: 3000px) {
  .lineYellow {
    width: 110px;
  }
}


</style>