<template>
  <div id="home" class="w-full h-screen pt-32 lg:pt-0 flex flex-col items-center justify-center bgWavesGray">
    <div class="absolute" style="top: 15%; left: 5%">
      <span class="ibmPlex text-md">SARUGAMI&nbsp;&nbsp;&nbsp;猿神 </span>
    </div>
    <div class="main">
      <img src="../../assets/images/backgrounds/background-home.png" class="object-fill hidden lg:block pt-20" style="scale: 75%">
      <img src="../../assets/images/backgrounds/background-home.png" class="object-fill block lg:hidden">
    </div>
  </div>
</template>

<script>
export default {
  name: "MainSection",
}
</script>

<style lang="scss">

#container {
  perspective: 25px;
}

#inner {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}

.main {
  margin-left: 15%;
  margin-right: 15%
}

@media screen and (max-width: 1024px) {
  .main {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 2044px) {
  .main {
    zoom: 200%;
  }
}

</style>
