<template>
  <div class="flex flex-row items-center justify-center h-full gap-3">
    <div class="h-full cursor-pointer w-6 2xl:w-8 flex flex-row items-center justify-center">
      <a href="https://discord.gg/sarugami" target="_blank">
        <img src="../assets/images/discord-gray.svg">
      </a>
    </div>
    <div style="border-left: 2px solid gray" class="h-full">&nbsp;</div>
    <div class="h-full cursor-pointer w-6 2xl:w-8 flex flex-row items-center justify-center">
      <a href="https://twitter.com/SarugamiNFT" target="_blank">
        <img src="../assets/images/twitter-gray.svg">
      </a>
    </div>
    <div style="border-left: 2px solid gray" class="h-full">&nbsp;</div>
    <div class="h-full cursor-pointer w-6 2xl:w-8 flex flex-row items-center justify-center">
      <a href="https://opensea.io/collection/sarugami" target="_blank">
        <img src="../assets/images/opensea-gray.svg">
      </a>
    </div>
    <div style="border-left: 2px solid gray" class="h-full">&nbsp;</div>
    <div class="h-full cursor-pointer w-4 2xl:w-7 flex flex-row items-center justify-center">
      <a href="https://etherscan.io/address/0x210F75E4EBEdF6F19DDa89950Fa7278030ec4ca3" target="_blank">
        <img src="../assets/images/etherscan-gray.svg">
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Socials',
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>