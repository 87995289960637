<template>
  <div class="teaserBG">
    <div class="flex flex-col w-full justify-center items-center">
      <div
          class="flex md:flex-row flex-col md:justify-between justify-center items-center pt-32 md:px-32 pb-10 w-full gap-2">
        <span class="poppins font-extrabold md:text-5xl text-2xl">
          Sarugami: One Shots, Mint I
        </span>
        <ConnectButtonSmall v-if="!isWrongChain"/>
      </div>
    </div>
    <div class="flex flex-col w-full justify-start items-center gap-4 text-center h-screen" v-if="!wallet">
    <span class="ibmPlex font-extrabold text-2xl">
      You need to connect!
    </span>

      <span class="ibmPlex mt-10">
       Connect your wallet to see if you<br/> are eligible for the mint.
    </span>
      <ConnectButtonSmall v-if="!isWrongChain"/>
      <div class="poppins text-red-400 w-full" v-else>
        Wrong network, change to Mainnet Ethereum
      </div>
    </div>
    <div class="flex flex-col w-full justify-start items-center md:gap-8 gap-4 text-center h-screen"
         v-if="wallet && isUserEligible && !mintDone">
      <img src="../assets/images/backgrounds/preview.png" class="md:w-1/6 w-4/6 rounded pb-8"/>
      <span class="ibmPlex font-extrabold text-2xl">
      You are eligible!
    </span>
      <span class="ibmPlex">
       Whitelisted wallets may mint<br/> one free One Shot. Reveal will<br/>happen after minting.
    </span>
      <MintButtonSmall @click="mint()"/>
      <div class="poppins text-red-400 w-full" v-if="result && result.success === false">
        {{ result.message }}
      </div>
    </div>
    <div class="flex flex-col w-full justify-start items-center md:gap-8 gap-4 text-center h-screen"
         v-if="wallet && !isUserEligible">
      <img src="../assets/images/backgrounds/preview.png" class="md:w-1/6 w-4/6 rounded pb-8"/>
      <span class="ibmPlex font-extrabold text-2xl">
      Sorry, you are not eligible.
    </span>
      <span class="ibmPlex">
       If you believe this is an error, <br/> please open a ticket in Discord.
    </span>
      <button
          class="bg-primary text-white font-semibold py-2 px-4 border border-primary hover:border-secondary rounded"
          @click="goToOpensea">
        Buy Sarugami on OpenSea
      </button>
    </div>
    <div class="flex flex-col w-full justify-start items-center md:gap-8 gap-4 text-center h-screen"
         v-if="wallet && isUserEligible && mintDone">
      <img src="../assets/images/backgrounds/preview.png" class="md:w-1/6 w-4/6 rounded pb-8"/>
      <span class="ibmPlex font-extrabold text-2xl">
      Thanks for minting!
    </span>
      <span class="ibmPlex">
        Reveal will happen after minting.
    </span>
      <button
          class="bg-primary text-white font-semibold py-2 px-4 border border-primary hover:border-secondary rounded"
          @click="goToOpensea">
        Buy Sarugami on OpenSea
      </button>
    </div>
  </div>
</template>

<script>

import ConnectButtonSmall from "@/components/buttons/ConnectButtonSmall";
import store from "@/store";
import MintButtonSmall from "@/components/buttons/MintButtonSmall";
import {getMerkleRoots, isWhitelisted} from "@/services/proxy";
import {mintWhitelist} from "@/services/sale";

export default {
  name: 'MintOneShots',
  components: {MintButtonSmall, ConnectButtonSmall},
  data() {
    return {
      isWrongChain: true,
      isUserEligible: false,
      mintDone: false,
      result: null,
      isLoaded: true
    }
  },
  async mounted() {
    if (store.state.wallet) {
      this.isUserEligible = isWhitelisted(store.state.wallet);
    }
    await this.isOnRightNetwork();
    getMerkleRoots()
  },
  computed: {
    wallet() {
      return store.state.wallet;
    }
  },
  watch: {
    async wallet() {
      this.isUserEligible = isWhitelisted(store.state.wallet);
      return store.state.wallet;
    }
  },
  methods: {
    goToOpensea() {
      window.open("https://opensea.io/collection/sarugami");
    },
    async mint() {
      this.isLoaded = false;
      this.result = await mintWhitelist(this.wallet)

      if (this.result.success) {
        this.mintDone = true;
      }

      this.isLoaded = true;
    },
    async isOnRightNetwork() {
      if (window.ethereum) {
        window.ethereum.on('chainChanged', async () => {
          await this.isOnRightNetwork();
        });

        const currentChainId = await window.ethereum.request({
          method: 'eth_chainId',
        });
        if (currentChainId === "0x1") { //Todo change here to 0x1 mainnet
          this.isWrongChain = false;
          return;
        }

        this.isWrongChain = true;
      }
    },
  }
}
</script>

<style scoped>
.teaserBG {
  background-image: url("../assets/images/backgrounds/teaserBG.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>