<template>
  <Slide right :width="width">
    <div class="flex flex-col justify-center items-center gap-10 inter" style="padding: 10px;">
      <div @click="goTo('')">
        Home
      </div>
      <div @click="goTo('lore')">
        Lore
      </div>
      <div @click="goTo('rarity')">
        Rarity
      </div>
      <div @click="goTo('sarugami-one-shots')">
        One Shots
      </div>
    </div>
  </Slide>
</template>

<script>
import { Slide } from 'vue3-burger-menu'
export default {
  name: "BurgerMenu",
  data: () => {
    return {
      width: screen.width
    }
  },
  components: {
    Slide
  },
  methods: {
    goTo(id) {
      this.$router.push("/" + id)
      document.querySelector('.bm-cross-button').click();
    },
  }
}
</script>

<style scoped
       lang="scss">
@import "./src/assets/css/variables";

.hoverHide:hover span {
  display: block;
  position: absolute;
  background-color: #F5C20F;
  border-radius: 15px;
  padding: 10px;
  margin: -12px;
}
</style>
